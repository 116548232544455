<template>
  <div class="flex flex-col flex-grow h-full">
    <h6 class="mb-10">Account Verification Required</h6>

    <p class="mb-2">
      Enter the verification code sent to the phone number attached to your bvn.
    </p>
    <p class="mb-10">
      Please be patient to receive the code
    </p>

    <form @submit.prevent="submit" class="flex flex-col flex-grow">
      <input-split
        class="mb-16"
        v-model="form.data.otp.value"
      />
      <div
        v-if="getFirstError('otp')"
        class="form-group-2-text mb-10 -mt-12 text-red-500"
      >
        {{ getFirstError('otp') }}
      </div>

      <!-- <p class="text-center mb-10">
        <sm-loader v-if="form.resending" />
        <span v-else>
          Didn’t get code?
          <a
            href="#!"
            class="font-bold text-blue-500 underline"
            @click.prevent="resendOtp"
          >
            Resend
          </a>
        </span>
      </p> -->

      <p v-if="form.sending" class="text-center mb-10">
        <sm-loader />
      </p>
      <p
        v-else-if="timer.isDone"
        class="my-5 text-center text-13 text-mbs-black"
      >
        Didn’t get OTP?
        <span
          class="font-bold text-blue-500 underline cursor-pointer"
          @click="requestOtp"
        >
          Resend
        </span>
        or
        <span
          class="font-bold text-blue-500 underline cursor-pointer"
          @click="setPreferenceToNin"
        >
          Verify With NIN
        </span>
      </p>

      <p v-else class="my-5 text-center text-13 text-mbs-black">
        Resend in {{ timer.time }} secs
      </p>

      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <div class="flex gap-4 mt-auto">
        <button
          type="submit"
          class="btn btn-blue btn-md mt-auto relative w-full"
          :disabled="form.loading || form.resending"
        >
          <sm-loader class="sm-loader-white" v-if="form.loading" />
          <span v-else>Proceed</span>
          <i class="absolute inline-flex right-20px top-1/2" style="transform: translateY(-50%);">
            <ion-icon name="arrow-forward-outline" />
          </i>
        </button>
      </div>
    </form>

  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'BVNOtpVerification',
  data() {
    return {
      form: this.$options.basicForm([
        'bvn', 'otp', 'otp_verification_id',
      ], { sending: false }),
      timer: {
        time: 60,
        interval: null,
        isDone: false
      }
    };
  },
  computed: {
    bvn() {
      return this.user?.profile?.bvn;
    },
    otpValue() {
      return this.form.data.otp.value;
    },
  },
  beforeMount() {
    this.mapData();
    this.requestOtp();
  },
  beforeDestroy() {
    this.stopCountDown();
  },
  watch: {
    otpValue(value) {
      if (value.length === 6) {
        this.submit();
      }
    },
  },
  methods: {
    ...mapActions('session', ['updateUser']),

    mapData() {
      this.form.data.bvn.value = this.bvn;
    },
    setPreferenceToNin() {
      this.$emit('prefer-nin');
    },
    startCountDown() {
      this.stopCountDown(this.timer);

      this.timer.isDone = false;
      this.timer.interval = setInterval(() => {
        this.timer.time -= 1;
        if (this.timer.time <= 0) {
          this.stopCountDown(this.timer);
          this.timer.isDone = true;
          this.timer.time = 60;
        }
      }, 1000);
    },
    stopCountDown() {
      clearTimeout(this.timer.interval);
    },
    async requestOtp() {
      // this.form.error = null;
      this.form.sending = true;
      await this.sendRequest('personal.onboarding.bvn.requestOtp', {
        data: {
          bvn: this.bvn,
        },
        success: (response) => {
          this.startCountDown();
          const { data: { otp_verification_id: otpVerificationId } } = response.data;
          this.form.data.otp_verification_id.value = otpVerificationId;
        },
        // error: (error) => {
        //   this.form.error = error;
        //   this.mapFormErrors(this.form, error?.response?.data?.errors);
        // },
      });
      this.form.sending = false;
    },
    async submit() {
      if (!this.validateForm(this.form)) return false;

      this.form.error = null;
      this.form.setLoading();
      await this.sendRequest('personal.onboarding.bvn.verifyOtp', {
        data: this.getFormData(),
        success: (response) => {
          const { data: { user, profile } } = response.data;
          this.updateUser({ ...user, profile })
            .then(() => {
              this.$emit('verify-otp', { user });
            });
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        },
      });
      this.form.setLoading(false);
    },
  },
}
</script>
