<template>
  <div class="flex flex-col h-full">
    <h6 class="mb-10">
      Verify Identity
    </h6>

    <p class="font-semibold mb-5">
      Enter national identification number below
    </p>

    <form
      class="flex flex-col flex-grow"
      @submit.prevent="submit"
      novalidate
    >
      <form-group
        v-model="form.data.nin.value"
        name="nin"
        :form="form"
        type="email"
      >
        NIN
      </form-group>

      <div class="flex mt-3 mb-6">
        <div class="flex">
          <ion-icon name="shield" class="text-green-500 mr-4 text-2xl"></ion-icon>
          <div class="text-2xs mr-2">
            We’re mandated by CBN to keep your NIN and other vital information highly secured and confidential.
          </div>
        </div>
        <div
          class="text-xs font-bold whitespace-no-wrap cursor-pointer text-right mt-2"
          @click.prevent="$emit('show-nin-help')"
        >
          Forgot NIN?
        </div>
      </div>
      <!-- <div class="flex -mt-2 mb-10">
        <span class="alert-icon bg-blue-500 mr-2 text-white">
          <ion-icon name="alert-outline" />
        </span>
        <span class="text-xs">
          Please note that your work email is strictly to verify your account.
          <br>
          We will not contact you via your work email.
        </span>
      </div> -->

      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <div class="flex gap-4 mt-auto">
        <button
          type="submit"
          class="btn btn-blue btn-md mt-auto relative w-full"
          :disabled="form.loading"
        >
          <sm-loader
            v-if="form.loading"
            class="sm-loader-white"
          />
          <span v-else>Proceed</span>
          <i
            class="absolute inline-flex right-20px top-1/2"
            style="transform: translateY(-50%);"
          >
            <ion-icon name="arrow-forward-outline" />
          </i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'NinOtpRequest',
  data() {
    return {
      form: this.$options.basicForm([
        { name: 'nin', rules: 'required|number|length:11' }
      ])
    }
  },
  mounted() {
    this.mapData();
  },
  methods: {
    mapData() {
      this.form.data.nin.value = this.user?.profile?.nin || '';
    },
    async submit() {
      if (!this.validateForm(this.form)) return false;

      this.form.error = null;
      this.form.setLoading();
      await this.sendRequest('personal.onboarding.nin.requestOtp', {
        data: this.getFormData(),
        success: (response) => {
          const {
            data: { otp_verification_id: otpVerificationId }
          } = response.data;
          const nin = this.getFormValue('nin');
          this.$emit('request-otp', { nin, otpVerificationId });
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        }
      });
      this.form.setLoading(false);
    },
  },
};
</script>
