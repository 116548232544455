<template>
  <div>
    <modal
      ref="modal"
      class-name="duration-100 h-550px pt-12 overflow-hidden w-full md:w-500px 2xl:w-600px"
    >
      <OtpVerification
        v-if="form.otpSent"
        :nin="form.data.nin.value"
        :otp-verification-id="form.data.otp_verification_id.value"
        @back="clearForm"
        @update-otp-verification-id="updateOtpVerificationId"
        @verify-otp="completeVerification"
      />
      <OtpRequest
        v-else-if="form.preferNin"
        @back="emitBackEvent"
        @request-otp="navigateToOtpVerification"
        @show-nin-help="showNinHelpModal"
      />
      <BvnOtpVerification
        v-else-if="isOpened"
        @prefer-nin="setPreferenceToNin"
        @verify-otp="completeVerification"
      />
    </modal>

    <modal className="w-full md:w-450px" ref="ninHelpModal">
      <h1 class="text-base md:text-xl font-bold mb-6">
        Need help finding out your NIN?
      </h1>

      <div class="border border-blue-200 rounded-sm mb-6">
        <div class="px-6 py-6">
          Just dial <span class="font-mono font-bold pt-2 pb-2 px-3 bg-blue-200 rounded-sm" style="letter-spacing: 1px">
            *346#
          </span>
        </div>
      </div>

      <div class="text-center">
        <button
          type="button"
          class="btn btn-blue btn-md"
          @click.prevent="$refs.ninHelpModal.close"
        >
          Okay
        </button>
      </div>

    </modal>
  </div>
</template>

<script>
export default {
  name: 'IdentityVerification',
  components: {
    BvnOtpVerification: require('./BvnOtpVerification').default,
    OtpRequest: require('./OtpRequest').default,
    OtpVerification: require('./OtpVerification').default,
  },
  data() {
    return {
      form: this.$options.basicForm([
        'nin',
        'otp',
        'otp_verification_id',
      ], {
        otpSent: false,
        preferNin: false,
      }),
      isOpened: false,
    };
  },
  mounted() {
    this.$emit('mounted');
  },
  methods: {
    clearForm() {
      this.form = this.resetForm(this.form);
    },
    close() {
      this.$refs.modal.close();
    },
    completeVerification() {
      console.log('should close')
      this.close();
      this.$emit('complete-nin-verification', {});
    },
    emitBackEvent() {
      this.$emit('back');
    },
    navigateToOtpVerification({ nin, otpVerificationId }) {
      this.form.data.nin.value = nin;
      this.form.data.otp_verification_id.value = otpVerificationId;
      this.form.otpSent = true;
    },
    open() {
      this.isOpened = true;
      this.$refs.modal.open();
    },
    setPreferenceToNin() {
      this.form.preferNin = true;
    },
    showNinHelpModal() {
      this.$refs.ninHelpModal.open();
    },
    updateOtpVerificationId({ otpVerificationId }) {
      this.form.data.otp_verification_id.value = otpVerificationId;
    }
  },
};
</script>
